import React, { useCallback } from 'react';
import { graphql } from 'gatsby';
import { Box } from '@material-ui/core';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import { PageTypeContextProvider, PAGE_TYPE_CASE } from '@context/PageTypeContext';
import { useColorMode } from '@context/ColorModeContext';
import { SET_COLOR_MODE } from '@context/ColorModeContext/actions';
import { useHeroBackground, useHeaderModeByBackground } from '@hooks/helpers';
import { EntryContextProvider } from '@context/EntryContext';
import Layout from '@c/Layout';
import ArticleContent from '@c/ArticleContent';
import VisibilitySensor from '@c/VisibilitySensor';
import Hero from '@c/Hero';
import Picture from '@c/Picture';
import RelatedEntries from '@c/RelatedEntries';

const useStyles = makeStyles(theme => ({
  category: {
    fontSize: '0.75rem',
    fontWeight: 600,
    textTransform: 'uppercase',
    lineHeight: 1.33,
    letterSpacing: '0.5px',
  },
}));

const HeroComponent = ({ hero, category, logo }) => {
  const classes = useStyles();

  return (
    <Hero {...hero} dangerouslySetInnerHTML={null}>
      {!!category && <div className={classes.category}>{category.title}</div>}

      {!!logo && (
        <Box mt={{ xs: 4, md: 7.5 }}>
          <Picture width='auto' alt={logo.title} {...logo} />
        </Box>
      )}

      <Box mt={{ xs: 5, md: 7.5 }} dangerouslySetInnerHTML={{ __html: hero.text }}></Box>
    </Hero>
  );
};

const Idea = ({
  data: {
    cms: { entries, relatedEntries, seomatic },
  },
  pageContext: { slug },
}) => {
  const theme = useTheme(),
    [, setBackground] = useColorMode(),
    entry = entries[0],
    hero = entry.hero[0],
    heroBackground = useHeroBackground(hero),
    headerMode = useHeaderModeByBackground(heroBackground),
    category = entry.category[0],
    logo = entry.logo[0],
    articleContent = entry.articleContent,
    relatedEntriesTitle = entry.relatedEntries[0]?.relatedTitle;

  const handleOnVisible = useCallback(
    id => {
      setBackground({
        type: SET_COLOR_MODE,
        background: { color: theme.colors.bg },
        header: {
          mode: hero && !hero.fullHeight ? headerMode : 'light',
          absolute: hero && !hero.fullHeight,
        },
        visibility: { visibleContentId: id },
      });
    },
    [setBackground, theme.colors.bg, hero, headerMode]
  );

  return (
    <PageTypeContextProvider value={PAGE_TYPE_CASE}>
      <EntryContextProvider entry={entry}>
        <Layout seomatic={seomatic}>
          {hero && hero.fullHeight && <HeroComponent hero={hero} category={category} logo={logo} />}

          {hero && !hero.fullHeight && (
            <VisibilitySensor id={`case-1-${slug}`} onVisible={handleOnVisible}>
              <HeroComponent hero={hero} category={category} logo={logo} />
            </VisibilitySensor>
          )}

          <ArticleContent content={articleContent} />

          <VisibilitySensor id={`case-2-${slug}`} onVisible={handleOnVisible}>
            <Box mb={{ xs: 12.5, md: 20 }}>
              <RelatedEntries title={relatedEntriesTitle} entries={relatedEntries} />
            </Box>
          </VisibilitySensor>
        </Layout>
      </EntryContextProvider>
    </PageTypeContextProvider>
  );
};

export default Idea;

export const query = graphql`
  query($slug: String!, $relatedEntriesIds: [cms_QueryArgument]!, $path: String!) {
    cms {
      entries(section: "ideas", slug: [$slug]) {
        id
        ... on cms_ideas_ideas_Entry {
          id
          title
          hero {
            ...heroFields
          }
          logo {
            url
            title
          }
          category {
            id
            title
          }
          articleContent {
            ...articleContentFields
          }
          relatedEntries {
            ...relatedEntriesFields
          }
        }
      }
      relatedEntries: entries(id: $relatedEntriesIds) {
        ...ideasBaseFields
      }
      seomatic(uri: $path) {
        ...seomaticFields
      }
    }
  }
`;
